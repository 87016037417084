<template>
  <div>
    <p class="mb-0">
      The hypothetical reactions shown below comprise the fictional atoms/molecules
      <chemical-latex content="A, B, C, and D\text{.}" />
      Which equilibrium process would
      <span class="text-bold text-danger">not be affected</span>
      by an increase in pressure?
    </p>

    <v-form @submit.prevent="submitResponse">
      <v-radio-group v-model="inputs.input1" :disabled="!allowEditing" class="mt-1 pl-6 mb-n6">
        <div v-for="option in options1" :key="option.value">
          <v-radio class="d-inline-block" :value="option.value" />
          <stemble-latex :content="option.expression" />
        </div>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '../displayers/ChemicalLatex';

export default {
  name: 'Question170f',
  components: {StembleLatex, ChemicalLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
      },
      options1: [
        {expression: '$\\ce{A(g) + 2B(g) <=> 2C(g)}$', value: '3n2'},
        {expression: '$\\ce{2A(g) <=> B(g) + C(g)}$', value: '2n2'},
        {expression: '$\\ce{A(s) + B(g) <=> 2C(g)}$', value: '1psn2'},
        {expression: '$\\ce{A(g) + B(g) + C(s) <=> 3D(g)}$', value: '2psn3'},
        {expression: '$\\ce{3A(g) <=> 2B(g) + C(s)}$', value: '3n2ps'},
      ],
    };
  },
};
</script>
